// Тип объекта в программе рассрочки
export const InstallmentRealtyTypes = {
    Undefined: 'Undefined',           // Неизвестный тип
    Apartment: 'Apartment',           // Апартаменты
    Commercial: 'Commercial',         // Коммерческая недвижимость
    CountryHouse: 'CountryHouse',     // Дом с земельным участком
    Flat: 'Flat',                     // Квартира
    House: 'House',                   // Дом
    LandPlot: 'LandPlot',             // Земельный участок
    ParkingSpace: 'ParkingSpace',     // Машиноместо
    Room: 'Room',                     // Комната
    Storage: 'Storage',               // Кладовка
    Townhouse: 'Townhouse'            // Таунхаус
} as const

// Тип оплаты в программе рассрочки
export const InstallmentPaymentTypes = {
    Undefined: 'Undefined',           // Неизвестный тип
    FixedMonthly: 'FixedMonthly',     // Фиксированный платеж
    Interval: 'Interval',             // Платеж, разбитый на интервалы
    Credit: 'Credit'                  // Кредит
} as const